import { useRef, useCallback, useState } from 'react';
import { split, last, defaultTo, map, fromPairs, pipe, prop, head, propOr, filter, propEq } from 'ramda'

import useForm from 'Hooks/useForm';
import { createFiscalData } from 'App/hexmodules/fiscalData/application/create/createFiscalData'
import { getFiscalData } from 'App/hexmodules/fiscalData/application/get/getFiscalData'
import { useDependencies } from 'App/DependencyContext';
import { fields, addressDefinition } from './definition'
import { getAllCountries } from 'App/hexmodules/country/application/getAll/getAllCountries'
import { getAllStates } from 'App/hexmodules/state/application/getAll/getAllStates'
import { getAllTaxRegime } from 'App/hexmodules/taxRegime/application/getAll/getAllTaxRegime'
import { addNotification } from 'Modules/principal'
import { useDispatch } from 'react-redux'

const getIdCif = pipe(
  propOr('', 'url'),
  split('?'),
  last,
  defaultTo(''),
  split('&'),
  map(split('=')),
  fromPairs,
  prop('D3'),
  split('_'),
  head,
);

const findTaxRegime = (taxRegime, allTaxRegime) => pipe(filter(propEq('descripcionCif', taxRegime)), head, prop('id'))(allTaxRegime)

const STATE = {
  INITIAL: 'initial',
  FETCHING_FISCAL_DATA: 'fetchingFiscalData',
  FETCHED_FISCAL_DATA: 'fetchedFiscalData',
  ERROR_FETCHING_FISCAL_DATA: 'errorFetchingFiscalData',
  SAVING_FISCAL_DATA: 'savingFiscalData',
  SAVED_FISCAL_DATA: 'savedFiscalData',
  ERROR_SAVING_FISCAL_DATA: 'errorSavingFiscalData',
};

function useFiscalData({ refresh }) {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const state = useRef(STATE.INITIAL);
  const [countries, setCountries] = useState([])
  const [states, setStates] = useState([])
  const [allTaxRegime, setAllTaxRegime] = useState([])

  const [formValues, setFormValues, handleOnChangeField, isValid, getValues, showErrors] = useForm({
    fields,
  });
  const [addressValues, mapAddressValues, , , getAddressValues] = useForm({
    fields: addressDefinition,
  });

  const { fiscalDataRepository } = useDependencies()
  const { countryRepository, stateRepository, taxRegimeRepository } = useDependencies();

  const initialFetch = async () => {
    if (state.current === STATE.INITIAL) {
      setIsLoading(true)
      state.current = STATE.FETCHING_FISCAL_DATA;
      const fiscalData = await getFiscalData(fiscalDataRepository);
      const countries = await getAllCountries(countryRepository);
      const states = await getAllStates(stateRepository);
      const allTaxRegimeResult = await getAllTaxRegime(taxRegimeRepository);
      setCountries(countries)
      setStates(states)
      setFormValues(fiscalData);
      mapAddressValues(fiscalData);
      setAllTaxRegime(allTaxRegimeResult)
      state.current = STATE.FETCHED_FISCAL_DATA;
      setIsLoading(false)
    }
  };

  initialFetch();

  const saveFiscalData = async () => {
    if (isValid()) {
      setIsLoading(true)
      try {
        state.current = STATE.SAVING_FISCAL_DATA;
        const fiscalData = getValues();
        fiscalData.address = getAddressValues();
        await createFiscalData(fiscalDataRepository, fiscalData)
        await refresh();
        state.current = STATE.SAVED_FISCAL_DATA;
        setIsLoading(false)
        dispatch(addNotification({
          type: 'success',
          message: 'Infomación guardada correctamente',
        }))
      } catch ({ response: { data } }) {
        dispatch(addNotification({
          type: 'danger',
          message: data?.error,
        }))
        setIsLoading(false)
      }
    }
  };

  const getCurrentState = useCallback(() => {
    return state.current;
  }, []);

  const onCifLoaded = (data) => {
    const idCif = getIdCif(data)
    setFormValues({
      ...data, idCif,
      taxRegime: findTaxRegime(data.regimen, allTaxRegime),
    })
    mapAddressValues({
      zipCode: data.cP,
      state: data.entidadFederativa,
      municipality: data.municipioODelegación,
      neighborhood: data.colonia,
      street: data.nombreDeLaVialidad,
      number: data.númeroExterior,
      interiorNumber: data.númeroInterior,
      country: '141',
    })
  }

  return {
    values: formValues,
    getCurrentState,
    onChangeField: handleOnChangeField,
    showErrors,
    saveFiscalData,
    onCifLoaded,
    addressValues,
    countries,
    states,
    allTaxRegime,
    isLoading,
  };
}

export default useFiscalData;
