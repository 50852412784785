import React, { Component } from 'react'
import { getDataInput, getValues, validateData, mapPropsToState, makeString } from 'App/helpers'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { bindActionCreators } from 'redux'
import { ActionBar } from 'Components/common'
import {
  Box, SubTitle, Divider, Columns, Column,
  Button,
  Icon,
  Modal,
  Message,
  Delete,
} from 'Components/common/bulma'
import { Select, Input } from 'Components/common/form'
import { AddressSection, ContactSection } from 'Components/catalogs/providers'
import {
  GeneralSection,
  PaymentMethod,
  ClabeAccount,
  School,
} from 'Components/catalogs/clients'
import { addNotification } from 'Modules/principal'
import {
  generalData, addressData, contactData,
  schoolData,
  metodoPago,
  clabeAccount,
  regimenFiscalReceptor,
} from './clientConfig'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { NACIONAL, EXTRANJERO } from '../../../constants'
import Show from 'Components/common/Show'
import {
  includes, flip, cond, pipe, equals, T, always, filter,
  prop, F, tap, find, propEq, lensPath, set, converge, or, propSatisfies,
  reject,
} from 'ramda'
import { toNumber } from 'ramda-adjunct';


import {
  getEstatusPersona,
  getTipoPersona,
  getDireccion,
  getPaises,
  getEntidades,
  getCfdiUsetype,
  getPaymentMethod,
  getRegimenFiscalAction,
} from 'Modules/catalogos'
import {
  endEditClient,
  createClient,
  getClient,
  deleteClient,
  updateClient,
} from 'Modules/catalogs/clients'
import CifLoader from '../components/cifLoader'
const tagsInput = require('bulma-tagsinput')


const TIPO_PERSONA_VENTA_MOSTRADOR = 6
const TIPO_PERSONA_EXTRANJERO = 4
const TIPO_PERSONA_EXTRANJERO_MORAL = 7
const RFC_EXTRANJERO = 'XEXX010101000'
const PAIS_MEXICO = '141'
const LONGITUD_CODIGO_POSTAL = 5

const isPointOfSale = equals(TIPO_PERSONA_VENTA_MOSTRADOR)
const isForeign = converge(or, [equals(TIPO_PERSONA_EXTRANJERO), equals(TIPO_PERSONA_EXTRANJERO_MORAL)])

const makePointOfSaleDefaultValues = (state) => {

  const RFC_VENTA_MOSTRADOR = 'XAXX010101000'
  const { generalData, regimenFiscalReceptor, addressData, metodoPago } = state

  const rfcLens = lensPath(['rfc', 'value'])
  const rfcStateLens = lensPath(['rfc', 'disabled'])
  const regimenFiscalLens = lensPath(['regimenFiscalReceptor', 'value'])
  const regimenFiscalStateLens = lensPath(['regimenFiscalReceptor', 'disabled'])
  const postalCodeLens = lensPath(['codigoPostal', 'value'])
  const postalCodeStateLens = lensPath(['codigoPostal', 'disabled'])
  const usoCfdiLens = lensPath(['tipoUsoCFDI', 'value'])
  const usoCfdiStateLens = lensPath(['tipoUsoCFDI', 'disabled'])
  const hasAddressCodeLens = lensPath(['direccion', 'value'])

  const newGeneralData = pipe(
    set(rfcLens, RFC_VENTA_MOSTRADOR),
    set(rfcStateLens, true),
  )

  const newRegimenFiscalReceptor = pipe(
    set(regimenFiscalLens, 12),
    set(regimenFiscalStateLens, true),
  )

  const newAddressData = pipe(
    set(postalCodeLens, '03100'),
    set(postalCodeStateLens, true),
    set(hasAddressCodeLens, false),
  )

  const newMetodoPago = pipe(
    set(usoCfdiLens, 23),
    set(usoCfdiStateLens, true),
  )

  return {
    generalData: newGeneralData(generalData),
    regimenFiscalReceptor: newRegimenFiscalReceptor(regimenFiscalReceptor),
    addressData: newAddressData(addressData),
    metodoPago: newMetodoPago(metodoPago),
  }
}

const makeForeignDefaultValues = (state) => {

  const { generalData, regimenFiscalReceptor, addressData, metodoPago } = state

  const rfcLens = lensPath(['rfc', 'value'])
  const rfcStateLens = lensPath(['rfc', 'disabled'])
  const regimenFiscalLens = lensPath(['regimenFiscalReceptor', 'value'])
  const regimenFiscalStateLens = lensPath(['regimenFiscalReceptor', 'disabled'])
  const postalCodeLens = lensPath(['codigoPostal', 'value'])
  const postalCodeStateLens = lensPath(['codigoPostal', 'disabled'])
  const usoCfdiLens = lensPath(['tipoUsoCFDI', 'value'])
  const usoCfdiStateLens = lensPath(['tipoUsoCFDI', 'disabled'])

  const newGeneralData = pipe(
    set(rfcLens, ''),
    set(rfcStateLens, false),
  )

  const newRegimenFiscalReceptor = pipe(
    set(regimenFiscalLens, 12),
    set(regimenFiscalStateLens, true),
  )

  const newAddressData = pipe(
    set(postalCodeLens, ''),
    set(postalCodeStateLens, false),
  )

  const newMetodoPago = pipe(
    set(usoCfdiLens, 23),
    set(usoCfdiStateLens, true),
  )

  return {
    generalData: newGeneralData(generalData),
    regimenFiscalReceptor: newRegimenFiscalReceptor(regimenFiscalReceptor),
    addressData: newAddressData(addressData),
    metodoPago: newMetodoPago(metodoPago),
  }
}

const resetFieldsValues = (state) => {
  const { generalData, regimenFiscalReceptor, addressData, metodoPago } = state

  const rfcLens = lensPath(['rfc', 'value'])
  const rfcStateLens = lensPath(['rfc', 'disabled'])
  const regimenFiscalLens = lensPath(['regimenFiscalReceptor', 'value'])
  const regimenFiscalStateLens = lensPath(['regimenFiscalReceptor', 'disabled'])
  const postalCodeLens = lensPath(['codigoPostal', 'value'])
  const postalCodeStateLens = lensPath(['codigoPostal', 'disabled'])
  const usoCfdiLens = lensPath(['tipoUsoCFDI', 'value'])
  const usoCfdiStateLens = lensPath(['tipoUsoCFDI', 'disabled'])

  const newGeneralData = pipe(
    set(rfcLens, ''),
    set(rfcStateLens, false),
  )

  const newRegimenFiscalReceptor = pipe(
    set(regimenFiscalLens, ''),
    set(regimenFiscalStateLens, false),
  )

  const newAddressData = pipe(
    set(postalCodeLens, ''),
    set(postalCodeStateLens, false),
  )

  const newMetodoPago = pipe(
    set(usoCfdiLens, ''),
    set(usoCfdiStateLens, false),
  )

  return {
    generalData: newGeneralData(generalData),
    regimenFiscalReceptor: newRegimenFiscalReceptor(regimenFiscalReceptor),
    addressData: newAddressData(addressData),
    metodoPago: newMetodoPago(metodoPago),
  }
}

export class Proveedores extends Component {
  state = {
    generalData,
    addressData,
    contactData,
    metodoPago,
    schoolData,
    clabeAccount,
    regimenFiscalReceptor,
    showErrors: false,
    client: undefined,
  }
  componentDidMount() {
    this.props.getEstatusPersona()
    this.props.getTipoPersona()
    this.props.getPaises()
    this.props.getEntidades()
    this.props.getCfdiUsetype()
    this.props.getPaymentMethod()
    this.props.getRegimenFiscal()
    this.emailTagged = new tagsInput('[type="tags"]', { delimiter: ';', duplicates: false })
    this.emailTagged.reset()
    this.emailTagged.element.addEventListener('change', e => {
      this.onChangeInput(e, 'contactData')
    })

    const id = this.props.match.params.id
    if (id && (id !== this.state.client)) {
      this.props.getClient(id)
    }
  }
  componentWillUnmount() {
    this.props.endEditClient()
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.client.id && nextProps.client.id !== this.state.client) {
      const generalData = mapPropsToState(this.state.generalData, nextProps.client)
      const addressData = mapPropsToState(this.state.addressData, nextProps.client)
      const contactData = mapPropsToState(this.state.contactData, nextProps.client)
      const metodoPago = mapPropsToState(this.state.metodoPago, nextProps.client)
      const clabeAccount = mapPropsToState(this.state.clabeAccount, nextProps.client)
      const schoolData = mapPropsToState(this.state.schoolData, nextProps.client)
      const regimenFiscalReceptor = mapPropsToState(this.state.regimenFiscalReceptor, nextProps.client)
      const calle = addressData.calle.value
      const direccion = addressData.direccion
      direccion.value = calle ? (calle !== "NA") : false;

      this.setState({
        client: nextProps.client.id,
        generalData,
        addressData: { ...addressData, direccion },
        contactData,
        metodoPago,
        clabeAccount,
        schoolData,
        regimenFiscalReceptor,
      }, () => {
        this.emailTagged.addTag(contactData.correoElectronico.value);
        this.initNacionalExtranjero(generalData.clienteNacionalOExtranjero)
        // this.onChangeTipoPersona(generalData.tipoDePersona)
      })
    }
  }

  onChangeInput = ({ target }, section) => {
    const { name } = target
    const data = getDataInput(target, this.state[section])
    const field = data[name]

    this.setState({
      [section]: data,
      showErrors: false,
      showAccountErrors: false,
    }, () => {
      if (field.trigger && this[field.trigger]) {
        this[field.trigger](field)
      }
    })
  }

  onChangeNacionalExtranjero = (field) => {
    const { generalData, addressData } = this.state

    if (field.value === EXTRANJERO) {
      generalData.tipoDePersona.value = 4
      addressData.direccion.value = true
      generalData.rfc.value = RFC_EXTRANJERO
      generalData.tipoDePersona.disabled = false
      generalData.rfc.disabled = true
      addressData.pais.disabled = false
    } else if (field.value === NACIONAL) {
      generalData.tipoDePersona.value = 1
      // generalData.rfc.value = CADENA_VACIA
      addressData.pais.value = PAIS_MEXICO
      generalData.rfc.disabled = false
      generalData.tipoDePersona.disabled = false
      addressData.pais.disabled = true
      this.onChangeCp(addressData.codigoPostal)
    }
    this.setState({
      generalData,
      addressData,
    })
  }

  initNacionalExtranjero = (field) => {
    const { generalData, addressData } = this.state

    if (field.value === EXTRANJERO) {
      generalData.tipoDePersona.disabled = true
      generalData.rfc.disabled = true
      addressData.pais.disabled = false
    } else if (field.value === NACIONAL) {
      generalData.rfc.disabled = false
      generalData.tipoDePersona.disabled = false
      addressData.pais.disabled = true
    }
    this.setState({
      generalData,
      addressData,
    })
  }

  onChangeCp = ({ value }) => {
    const { generalData, addressData } = this.state
    if (value.length === LONGITUD_CODIGO_POSTAL
      && generalData.clienteNacionalOExtranjero.value === NACIONAL) {
      this.props.getDireccion(value).then((response) => {
        if (response.length) {
          const { estado, municipio, asentamiento } = response[0]

          addressData.entidadFederativa.value = estado
          addressData.municipio.value = municipio
          addressData.colonia.value = asentamiento

          this.setState({
            addressData,
            generalData,
          })
        }
      })
    }
  }

  onChangeDireccion = ({ value }) => {
    const data = addressData;

    data.direccion.value = value
    if (!value) {
      this.setState({
        addressData: data
      })
    }
  }

  onChangeTipoPersona = ({ value }) => {
    const newState = cond([
      [isPointOfSale, () => makePointOfSaleDefaultValues(this.state)],
      [isForeign, () => makeForeignDefaultValues(this.state)],
      [T, () => resetFieldsValues(this.state)],
    ])(+value)

    this.setState(newState)
  }

  create = (callback) => {
    const { data: addressData, hasErrors: hasDireccionError } = validateData(this.state.addressData)
    const { data: generalData, hasErrors: hasGeneralError } = validateData(this.state.generalData)
    const { data: contactData, hasErrors: hascontactoError } = validateData(this.state.contactData)
    const { data: metodoPago, hasErrors: hasMetodoError } = validateData(this.state.metodoPago)
    const { data: clabeAccount, hasErrors: hasClabeError } = validateData(this.state.clabeAccount)

    const { data: schoolData, hasErrors: hasSchoolError } = validateData(this.state.schoolData)

    const { data: regimenFiscalReceptor, hasErrors: hasRegimenError } = validateData(this.state.regimenFiscalReceptor)

    const hasSchool = +this.props.permissions.escuela === 1

    if (hasDireccionError || hasGeneralError || hascontactoError || hasMetodoError || hasClabeError || (hasSchoolError && hasSchool) || hasRegimenError) {
      this.setState({
        addressData,
        generalData,
        contactData,
        metodoPago,
        clabeAccount,
        schoolData,
        regimenFiscalReceptor,
        showErrors: true,
      })
      this.props.addNotification({
        type: 'danger',
        message: '¡Favor de validar algunos campos!'
      })
      return
    }
    const direccionValues = getValues(this.state.addressData)
    const generalValues = getValues(this.state.generalData)
    const contactoValues = getValues(this.state.contactData)
    const metodoPagoValues = getValues(this.state.metodoPago)
    const clabeAccountValues = getValues(this.state.clabeAccount)
    const schoolValues = getValues(this.state.schoolData)
    const regimenFiscaValues = getValues(this.state.regimenFiscalReceptor)

    const { updateClient, createClient } = this.props

    const action = this.state.client ? updateClient : createClient
    const id = this.props.match.params.id
    action({
      ...direccionValues,
      ...contactoValues,
      ...generalValues,
      ...metodoPagoValues,
      ...clabeAccountValues,
      ...schoolValues,
      ...regimenFiscaValues,
      id: this.state.client,
    }).then((data) => {
      this.props.addNotification({
        type: 'success',
        message: 'El cliente se creó/actualizó correctamente'
      })
      id || this.props.push(`/catalogos/clientes/${data.id}`)
      typeof callback === 'function' && callback()
    }).catch((data) => {
      this.props.addNotification({
        type: 'danger',
        message: 'Upssss, Ocurrió un error'
      })
    })
  }

  onCancel = () => {
    this.props.push('/catalogos/clientes')
  }

  getPermissions = () => {
    const { client } = this.state
    return {
      canDelete: !!client,
      canSave: true,
    }
  }

  toggleModal = () => {
    this.setState((state) => ({
      showModal: !state.showModal,
    }))
  }

  delete = () => {
    this.props.deleteClient(this.state.client)
      .then(() => {
        this.props.addNotification({
          type: 'success',
          message: 'El proveedor se eliminó correctamente'
        })
        this.props.push('/catalogos/clientes')
      })
  }
  customColActions = (row) => (
    <Button danger outlined onClick={() => this.toggleAccountModal(row)}>
      <Icon>
        <FontAwesomeIcon icon={faTrash} />
      </Icon>
    </Button>
  )

  filterRegimenFiscal = (tipoPersona, regimenFiscal) => {
    const invertedIncludes = flip(includes)
    if (!tipoPersona) {
      return
    }

    const filterCond = cond([
      [invertedIncludes([4, 6, 7]), always(filter(pipe(prop('id'), toNumber, invertedIncludes([12, 20]))))],
      [equals(1), always(filter(pipe(prop('moral'), toNumber, equals(1))))],
      [equals(2), always(filter(pipe(prop('fisica'), toNumber, equals(1))))],
      [T, always(filter(F))]
    ])(tipoPersona)

    return filterCond(regimenFiscal)
  }


  onCifLoaded = (data) => {

    const isPhisical = data.rfc.length === 13
    const makeFullName = makeString`${'nombre'} ${'apellidoPaterno'} ${'apellidoMaterno'}`;
    const name = isPhisical ? makeFullName(data) : data.denominacionORazonSocial
    const regimen = find(propEq('descripcionCif', data.regimen))(this.props.regimenFiscal)

    const generalData = mapPropsToState(this.state.generalData, {
      rfc: data.rfc,
      nombreORazonSocial: name,
      uriCsf: data.url,
      clienteNacionalOExtranjero: NACIONAL,
      tipoDePersona: isPhisical ? 2 : 1,

    })

    const addressData = mapPropsToState(this.state.addressData, {
      direccion: true,
      pais: 141,
      entidadFederativa: data.entidadFederativa,
      municipio: data.municipioODelegacion,
      colonia: data.colonia,
      calle: data.nombreDeLaVialidad,
      exterior: data.numeroExterior,
      interior: data.numeroInterior,
      codigoPostal: data.cP,
    })

    const regimenFiscalReceptor = mapPropsToState(this.state.regimenFiscalReceptor, {
      regimenFiscalReceptor: regimen?.id,
    })

    const contactData = mapPropsToState(this.state.contactData, {
      correoElectronico: data.correoElectronico,
    })

    this.setState({ generalData, addressData, regimenFiscalReceptor, contactData }, () => {
      this.emailTagged.addTag(contactData.correoElectronico.value);
    })
  }

  getCfdiUseType = (tipoPersona) => {
    const flipIncludes = flip(includes)
    const campo = cond([
      [equals(1), always('aplicaTipoPersonaMoral')],
      [equals(2), always('aplicaTipoPersonaFisica')],
      [T, always('')],
    ])(tipoPersona)

    if (includes(tipoPersona, [4, 6, 7])) {
      return pipe(filter(pipe(prop('id'), toNumber, equals(23))))(this.props.cfdiUseType)
    } else {
      return pipe(reject(propSatisfies(pipe(toNumber, flipIncludes([16, 17, 18, 21, 24, 25])), 'id')), filter(pipe(prop(campo), equals('Sí'))))(this.props.cfdiUseType)
    }
  }

  filterTipoPersona = () => {
    const flipIncludes = flip(includes)
    if (this.state.generalData.clienteNacionalOExtranjero.value === EXTRANJERO) {
      return filter(propSatisfies(pipe(toNumber, tap(console.log), flipIncludes([4, 7])), 'id'))(this.props.tipoPersona)
    } else {
      return reject(propSatisfies(pipe(toNumber, flipIncludes([4, 7])), 'id'))(this.props.tipoPersona)
    }
  }

  render() {
    const { paises, estatusPersona, entidades, client, paymentMethod } = this.props
    return (
      <Box >
        <Modal isActive={this.state.showModal}>
          <Message danger>
            <Message.Header>
              <p>Eliminar cliente</p>
              <Delete onClick={this.toggleModal} />
            </Message.Header>
            <Message.Body className="has-text-centered">
              ¿Esta seguro de querer eliminar el cliente
              <br />
              <strong>{client.nombreORazonSocial}</strong>
              <div className="margin-top-lg">
                <Button danger onClick={this.delete}>Eliminar</Button>
                <Button default className="margin-left-sm" onClick={this.toggleModal}>
                  Cancelar
                </Button>
              </div>
            </Message.Body>
          </Message>
        </Modal>
        <SubTitle className="has-text-centered" is='3'>Catálogo de clientes</SubTitle>
        <ActionBar
          permissions={this.getPermissions()}
          onSave={this.create}
          onCancel={this.onCancel}
          onDelete={this.toggleModal}
          basicRole={['Oalta']}
        />
        <form>
          <Divider content="DATOS GENERALES" />
          <Columns>
            <Column className="is-half">
              <CifLoader
                onCifLoaded={this.onCifLoaded}
              />
            </Column>
          </Columns>
          <GeneralSection
            data={this.state.generalData}
            estatusPersona={estatusPersona}
            tipoPersona={this.filterTipoPersona()}
            onChange={(e) => this.onChangeInput(e, 'generalData')}
            showErrors={this.state.showErrors}
          />
          <Divider content="DIRECCIÓN" />
          <Columns>
            <Column className="is-6">
              <Select
                onChange={(e) => this.onChangeInput(e, 'addressData')}
                options={[
                  { value: false, label: 'Sin Dirección' },
                  { value: true, label: 'Con Dirección' },
                ]}
                showErrors={this.state.showErrors}
                {...this.state.addressData.direccion}
              />
            </Column>
            <Show canShow={!this.state.addressData.direccion.value}>
              <Column>
                <Input
                  onChange={e => this.onChangeInput(e, 'addressData')}
                  {...this.state.addressData.codigoPostal}
                  maxLength="5"
                  showErrors={this.state.showErrors}
                />
              </Column>
            </Show>
          </Columns>
          {this.state.addressData.direccion.value && (
            <AddressSection
              data={this.state.addressData}
              onChange={e => this.onChangeInput(e, 'addressData')}
              paises={paises}
              entidades={entidades}
              extranjero={this.state.generalData.clienteNacionalOExtranjero.value === EXTRANJERO}
              showErrors={this.state.showErrors}
            />)}
          <Divider content="DATOS DE CONTACTO" />
          <ContactSection
            data={this.state.contactData}
            onChange={e => this.onChangeInput(e, 'contactData')}
            showErrors={this.state.showErrors}
          />
        </form>
        <Divider content="METODO DE PAGO" />
        <PaymentMethod
          data={this.state.metodoPago}
          onChange={e => this.onChangeInput(e, 'metodoPago')}
          showErrors={this.state.showErrors}
          cfdiUseType={this.getCfdiUseType(+this.state.generalData.tipoDePersona.value)}
          paymentMethod={paymentMethod}
        />

        <Show canShow={+this.props.permissions.escuela === 1} >
          <Divider content="ESCUELA" />
          <School
            data={this.state.schoolData}
            onChange={e => this.onChangeInput(e, 'schoolData')}
            showErrors={this.state.showErrors}
          />
        </Show>
        <Divider content="REGIMEN FISCAL RECEPTOR" />
        <Columns>
          <Column className="is-half">
            <Select
              onChange={(e) => this.onChangeInput(e, 'regimenFiscalReceptor')}
              options={this.filterRegimenFiscal(+this.state.generalData.tipoDePersona.value, this.props.regimenFiscal)}
              showErrors={this.state.showErrors}
              {...this.state.regimenFiscalReceptor.regimenFiscalReceptor}
            />

          </Column>
        </Columns>
        <Divider content="CUENTAS CLABE Y CONVENIO CIE PARA PAGO" />
        <ClabeAccount
          data={this.state.clabeAccount}
          onChange={e => this.onChangeInput(e, 'clabeAccount')}
          showErrors={this.state.showErrors}
        />
      </Box>
    )
  }
}

const mapStateToProps = ({ catalogos, catalogs, principal }) => ({
  estatusPersona: catalogos.estatusPersona,
  tipoPersona: catalogos.tipoPersona,
  direccion: catalogos.direccion,
  paises: catalogos.paises,
  entidades: catalogos.entidades,
  client: catalogs.clients.client,
  paymentMethod: catalogos.paymentMethod,
  cfdiUseType: catalogos.cfdiUseType,
  regimenFiscal: catalogos.regimenFiscal,
  isFetching: catalogs.clients.isFetchingClient,
  permissions: principal.inventoryPermission,
})

const mapDispatchToProps = dispatch => bindActionCreators({
  getEstatusPersona,
  getTipoPersona,
  getDireccion,
  getPaises,
  createClient,
  addNotification,
  getEntidades,
  push,
  endEditClient,
  getCfdiUsetype,
  getPaymentMethod,
  getClient,
  deleteClient,
  updateClient,
  getRegimenFiscal: getRegimenFiscalAction,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Proveedores)
